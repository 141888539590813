#page-settings {
   width: 100vw;
   height: 100vh;
   background: var(--background);
   overflow: auto;
   overflow-x: hidden;
}

#page-settings .content {
   height: calc(100% - 8rem);
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   margin-top: 4.5rem;
}

#page-settings .content .header {
   width: 100%;
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   margin-bottom: 4.1rem;
}

#page-settings .content h1 {
   font-family: 'Montserrat', sans-serif;
   font-size: 3.6rem;
   font-weight: 600;
   line-height: 4.4rem;
   color: var(--black-grey);
}

#page-settings .content span {
   font-family: 'Montserrat', sans-serif;
   font-size: 1.2rem;
   font-weight: 300;
   line-height: 2.2rem;
   color: var(--black-grey);
}

#page-settings .content .back-button {
   width: 12rem;
   height: 4.5rem;
   border: 1px solid var(--black-grey);
   border-radius: 0.4rem;
   display: flex;
   justify-content: center;
   align-items: center;
   background-color: transparent;
   font-family: 'Montserrat', sans-serif;
   font-size: 1.44rem;
   font-weight: 400;
   line-height: 1.8rem;
   color: var(--black-grey);
   margin-left: 2rem;
   text-decoration: none;
   transition: opacity 0.2s;
}

#page-settings .content .back-button:hover {
   opacity: 0.6;
}

#page-settings .content .settings-button {
   width: 27.2rem;
   height: 5.7rem;
   background: var(--white);
   border: 1px solid rgba(192, 192, 192, 0.5);
   box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
   border-radius: 0.6rem;
   display: flex;
   flex-direction: row;
   justify-content: flex-start;
   align-items: center;
   font-family: 'Montserrat', sans-serif;
   font-size: 1.8rem;
   font-weight: 500;
   line-height: 2.7rem;
   color: #AEAEAE;
   padding-left: 4rem;
   transition: color 0.2s;
   transition: border 0.2s;
   text-decoration: none;
   margin-bottom: 1.4rem;
   cursor: pointer;
}

#page-settings .content .settings-button svg {
   margin-right: 1rem;
}

#page-settings .content .settings-button:hover {
   color: var(--blue-color);
   border-bottom: 4px solid var(--blue-color);
}
