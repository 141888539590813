#page-tarja2 {
   flex: 1;
   width: 100vw;
   background: var(--background);
   overflow-x: hidden;
}

#page-tarja2 .container {
   height: calc(100vh - 12.5rem);
}

#page-tarja2 .container .header-title {
   margin-top: 4.5rem;
}

#page-tarja2 .container .header-title h1 {
   font-family: 'Montserrat', sans-serif;
   font-size: 3.6rem;
   font-weight: 600;
   line-height: 4.4rem;
   color: var(--black);
   margin-bottom: 1.8rem;
}

#page-tarja2 .container .header-title span {
   font-family: 'Montserrat', sans-serif;
   font-size: 1.4rem;
   font-weight: 600;
   line-height: 2.1rem;
   color: var(--label);
}

#page-tarja2 .container main {
   margin-top: 3rem;
   padding-bottom: 4rem;
   z-index: 0;
}

#page-tarja2 .vessel-input-container {
   margin-bottom: 6rem;
}

#page-tarja2 .vessel-input-container .input-block {
   margin: 0;
}

#page-tarja2 .vessel-input-container .input-block:focus-within::after {
   width: calc(24.3rem - 3.2rem);
   height: 1px;
   content: '';
   background: var(--blue-color);
   position: absolute;
   left: 1.6rem;
   right: 1.6rem;
   bottom: 0.2rem;
}

#page-tarja2 .vessel-input-container .input-block label {
   color: #32264C;
}

#page-tarja2 .input-block .react-select-container {
   width: 24.3rem;
   height: 3.2rem;
   margin-top: 0.5rem;
   border-radius: 0.8rem;
   border: 1px solid var(--border-line);
   outline: none;
   padding: 0 1rem;
   font-family: 'Montserrat', sans-serif;
   font-size: 1.2rem;
   font-weight: 400;
   color: var(--black-grey);
   cursor: pointer;
}

#page-tarja2 .input-block .react-select__control {
   width: 24.3rem;
   height: 3.2rem;
   border: 0;
   background-color: transparent;
   display: flex;
   align-items: center;
}

#page-tarja2 .input-block .react-select__value-container {
   width: 24.3rem;
   height: 3.2rem;
   border: 0;
   background-color: transparent;
   display: flex;
   align-items: center;
   position: static;
}

#page-tarja2 .input-block .react-select__placeholder {
   padding: 0;
   margin: 0;
}

#page-tarja2 .input-block .react-select__input {
   height: 0;
   border: 0;
   background-color: transparent;
}

#page-tarja2 .input-block .react-select__menu {
   width: 93%;
}

#page-tarja2 .input-block .react-select__option:active {
   background-color: #4B81E8;
   color: #FFF;
}

#page-tarja2 .input-block .react-select__indicators {
   display: none;
}

#page-tarja2 .input-block .react-select__menu-list::-webkit-scrollbar {
   width: 0.7rem;
}

#page-tarja2 .input-block .react-select__menu-list::-webkit-scrollbar-thumb {
   background: #AAA;
   border-radius: 1rem;
}

#page-tarja2 .input-block .react-select__menu-list::-webkit-scrollbar-thumb:hover {
   background: #888;
}

#page-tarja2 .container .button-submit {
   width: 30rem;
   height: 8rem;
   display: flex;
   justify-content: center;
   align-items: center;
   border-radius: 0.4rem;
   background: var(--green-color);
   font-family: 'Montserrat', sans-serif;
   font-size: 2.6rem;
   font-weight: 700;
   line-height: 3.2rem;
   color: var(--white);
   transition: background-color 0.2s;
   border: 0;
   text-decoration: none;
}

#page-tarja2 .container .button-submit:hover {
   background: var(--green-color-dark);
}

#page-tarja2 .container .button-load {
   width: 16rem;
   height: 4.5rem;
   display: flex;
   justify-content: center;
   align-items: center;
   border-radius: 0.4rem;
   background: var(--blue-color);
   font-family: 'Montserrat', sans-serif;
   font-size: 1.44rem;
   font-weight: 700;
   line-height: 1.8rem;
   color: var(--white);
   transition: background-color 0.2s;
   border: 0;
}

#page-tarja2 .container .button-load:hover {
   background: var(--blue-color-dark);
}

#page-tarja2 .container .button-load-disabled {
   width: 16rem;
   height: 4.5rem;
   display: flex;
   justify-content: center;
   align-items: center;
   border-radius: 0.4rem;
   background: var(--blue-color);
   font-family: 'Montserrat', sans-serif;
   font-size: 1.44rem;
   font-weight: 700;
   line-height: 1.8rem;
   color: var(--white);
   transition: background-color 0.2s;
   border: 0;
   cursor: default;
}
