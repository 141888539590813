.input-block {
   position: relative;
   display: flex;
   flex-direction: column;
   margin: 1.6rem auto;
}

.input-block+.input-block {
   margin-top: 0.6rem;
}

.input-block label {
   font-family: 'Poppins';
   font-size: 1.2rem;
   font-weight: 400;
   line-height: 2.2rem;
   color: var(--white);
}

.input-block input {
   width: 100%;
   height: 4rem;
   margin-top: 0.8rem;
   border-radius: 0.8rem;
   background: var(--white);
   border: 1px solid var(--border-line);
   outline: none;
   padding: 0 1.6rem;
   font-size: 1.3rem;
   font-family: 'Montserrat', sans-serif;
   font-weight: 400;
   color: var(--black-grey);
   line-height: 2.4rem;
}

.input-block input::placeholder {
   color: var(--placeholder);
   font-family: 'Montserrat', sans-serif;
   font-size: 1.3rem;
   font-weight: 400;
   line-height: 2.4rem;
}

.input-block input:-webkit-autofill {
   -webkit-box-shadow: 0 0 0px 1000px var(--white) inset !important;
   box-shadow: 0 0 0px 1000px var(--white) inset !important;
}

.input-block input:focus {
   border: 2px solid var(--blue-color);
}

.input-block:focus-within::after {
   width: calc(100% - 2.5rem);
   height: 1px;
   content: '';
   background: var(--blue-color);
   position: absolute;
   left: 1.6rem;
   right: 1.6rem;
   bottom: 0.7rem;
}
