:root {
   font-size: 40%;
   --background: #F7F8FB;
   --white: #FFFFFF;
   --black: #000000;
   --black-grey: #404040;
   --red-color: #ED1C24;
   --red-color-dark: #D8191F;
   --beige-color: #E9E2D4;
   --green-color: #6EE1B8;
   --green-color-dark: #48B992;
   --green-dark: #96B3B1;
   --gray-color: #EDEFF5;
   --blue-color: #4B81E8;
   --blue-color-dark: #155FEC;
   --border-line: #E6E6F0;
   --placeholder: #C1BCCC;
   --label: #5D5D5D;
   --label-secondary: #808080;
   --cards: rgba(255, 255, 255, 0.75);
}

* {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
}

html, body, #root {
   height: 100vh;
}

body {
   background: var(--background);
}

/* #root {
   display: flex;
   align-items: center;
   justify-content: center;
} */

.container {
   width: 90vw !important;
   max-width: 1140px !important;
}

@media (min-width: 1100px) {
   :root {
      font-size: 62.5%;
   }
}
