#page-details-portTerminal {
   background: var(--background);
   overflow: hidden;
}

#page-details-portTerminal .container {
   width: 100%;
   height: calc(100vh - 18rem);
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
   margin-top: 2rem;
}

#page-details-portTerminal .container .container-left {
   width: 100%;
   height: 100%;
   display: flex;
   flex: 1;
   margin-right: 0.5rem;
}

#page-details-portTerminal .container .container-right {
   width: 100%;
   height: 100%;
   display: flex;
   flex: 1;
   flex-direction: column;
   margin-left: 0.5rem;
}

#page-details-portTerminal .container .container-right h2 {
   font-family: 'Montserrat', sans-serif;
   font-size: 1.4rem;
   font-weight: 600;
   line-height: 2.1rem;
   color: var(--label);
   margin-bottom: 1.5rem;
}

#page-details-portTerminal .container .container-right .form {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
}

#page-details-portTerminal .container .container-right .form .button-date-picker {
   width: 23.5rem;
   height: 3.2rem;
   border-radius: 0.8rem;
   background: var(--white);
   border: 1px solid var(--border-line);
   outline: none;
   margin-top: 0.2rem;
   padding: 0 1.6rem;
   font-family: 'Montserrat', sans-serif;
   font-size: 1.2rem;
   font-weight: 400;
   line-height: 2.4rem;
   color: var(--black-grey);
   display: flex;
   justify-content: flex-start;
   align-items: center;
}

#page-details-portTerminal .container .container-right .form .button-date-picker:disabled {
   background: var(--gray-color);
}
