#links-navigation {
   width: 100vw;
   height: 4rem;
   overflow: hidden;
   background: var(--background);
   display: flex;
}

#links-navigation .container {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
}

#links-navigation .container a {
   width: 100%;
   text-decoration: none;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}

#links-navigation .container .link {
   font-family: 'Montserrat', sans-serif;
   font-weight: 400;
   font-size: 1.2rem;
   line-height: 1.8rem;
   color: #808080;
   text-align: center;
   text-transform: uppercase;
   margin-bottom: 0.5rem;
}

#links-navigation .container .link:hover {
   color: var(--black);
}

#links-navigation .container .link-selected {
   font-family: 'Montserrat', sans-serif;
   font-weight: 600;
   font-size: 1.2rem;
   line-height: 1.8rem;
   color: #5D5D5D;
   text-align: center;
   text-transform: uppercase;
   margin-bottom: 0.5rem;
}

#links-navigation .container .link-selected:hover {
   color: var(--black);
}

#links-navigation .container .line {
   height: 0.3rem;
   width: 100%;
   background: var(--background);
}

#links-navigation .container .line-selected {
   height: 0.3rem;
   width: 100%;
   background: var(--red-color);
}
