#page-header {
   height: 8rem;
   width: 100vw;
   display: flex;
   background: var(--white);
}

#page-header .container {
   flex: 1;
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
}

#page-header .container img {
   height: 3.2rem;
   width: 13.6rem;
}

#page-header .container .container-right {
   display: flex;
   flex-direction: row;
   align-items: center;
}

#page-header .container .container-right .button-register {
   width: 18.5rem;
   height: 4.5rem;
   background-color: var(--blue-color);
   border: 0;
   border-radius: 0.4rem;
   display: flex;
   flex-direction: row;
   justify-content: space-evenly;
   align-items: center;
   font-family: 'Montserrat', sans-serif;
   font-size: 1.44rem;
   font-weight: 400;
   line-height: 1.8rem;
   color: var(--white);
   transition: filter 0.2s;
   margin-right: 1.5rem;
}

#page-header .container .container-right .button-register:hover {
   filter: brightness(0.9);
}

#page-header .container .container-right .button-report {
   width: 18.5rem;
   height: 4.5rem;
   background-color: var(--white);
   border: 2px solid var(--blue-color);
   border-radius: 0.4rem;
   display: flex;
   flex-direction: row;
   justify-content: space-evenly;
   align-items: center;
   font-family: 'Montserrat', sans-serif;
   font-size: 1.44rem;
   font-weight: 400;
   line-height: 1.8rem;
   color: var(--blue-color);
   transition: filter 0.2s;
   margin-right: 2rem;
   z-index: 1;
}

#page-header .container .container-right .button-report:hover {
   filter: brightness(0.95);
}

#page-header .container .container-right .dashboard-button {
   width: 6rem;
   height: 4.5rem;
   background-color: #FFF;
   border: 2px solid var(--blue-color);
   border-radius: 0.4rem;
   display: flex;
   align-items: center;
   justify-content: center;
   margin-right: 2rem;
   transition: filter 0.2s;
   z-index: 1;
}

#page-header .container .container-right .dashboard-button:hover {
   filter: brightness(0.95);
}

#page-header .container .container-right a svg {
   color: var(--blue-color);
   transition: color 0.2s;
}

#page-header .container .container-right a svg:hover {
   color: var(--blue-color-dark);
}

#page-header .button-container {
   display: flex;
   flex-direction: row;
   justify-content: flex-start;
   align-items: center;
}

#page-header .button-container .back-button {
   width: 12rem;
   height: 4.5rem;
   border: 1px solid var(--black-grey);
   border-radius: 0.4rem;
   display: flex;
   justify-content: center;
   align-items: center;
   background-color: transparent;
   font-family: 'Montserrat', sans-serif;
   font-size: 1.44rem;
   font-weight: 400;
   line-height: 1.8rem;
   color: var(--black-grey);
   margin-left: 2rem;
   text-decoration: none;
   transition: opacity 0.2s;
}

#page-header .button-container .back-button:hover {
   opacity: 0.6;
}
