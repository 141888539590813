#page-scheduling-request {
   flex: 1;
   width: 100vw;
   background: var(--background);
   overflow-x: hidden;
}

#page-scheduling-request .var-height {
   height: calc(100vh - 12.5rem);
}

#page-scheduling-request .var-height-with-footer {
   height: calc(100vh - 20.5rem);
}

#page-scheduling-request .container .header-title {
   margin-top: 4.5rem;
}

#page-scheduling-request .container .header-title h1 {
   font-family: 'Montserrat', sans-serif;
   font-size: 3.6rem;
   font-weight: 600;
   line-height: 4.4rem;
   color: var(--black);
   margin-bottom: 1.8rem;
}

#page-scheduling-request .container .header-title span {
   font-family: 'Montserrat', sans-serif;
   font-size: 1.4rem;
   font-weight: 600;
   line-height: 2.1rem;
   color: var(--label);
}

#page-scheduling-request .container main {
   margin-top: 3rem;
   padding-bottom: 4rem;
   z-index: 0;
}

#page-scheduling-request .container main .filter-selected {
   display: flex;
   flex-direction: row;
   justify-content: flex-start;
   align-items: center;
   margin-bottom: 2rem;
}

#page-scheduling-request .container main .filter-selected .filter-title {
   font-family: 'Montserrat', sans-serif;
   font-size: 1rem;
   font-weight: 400;
   line-height: 1.6rem;
   color: var(--label);
   margin-right: 1.5rem;
}

#page-scheduling-request .container main .filter-selected .filter-selected-container {
   min-width: 7rem;
   height: 3rem;
   background-color: #EFF5FF;
   border-radius: 0.6rem;
   border: 1px solid rgba(0, 0, 0, 0.1);
   box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.05);
   display: flex;
   justify-content: space-evenly;
   align-items: center;
   font-family: 'Montserrat', sans-serif;
   font-size: 1rem;
   font-weight: 400;
   line-height: 1.6rem;
   color: var(--label);
   margin-right: 1.5rem;
   padding: 0 1rem;
}

#page-scheduling-request .container main .filter-selected .filter-selected-container span {
   margin-left: 0.5rem;
}

#page-scheduling-request .container main .filter-selected .filter-selected-container .filter-uppercase {
   text-transform: uppercase;
}

#page-scheduling-request .container main .filter-selected .filter-selected-container .filter-capitalize {
   text-transform: capitalize;
}

#page-scheduling-request .container main .filter-selected .filter-selected-container svg {
   cursor: pointer;
}

#page-scheduling-request .container main .no-vehicles {
   margin-top: 5rem;
   font-family: 'Montserrat', sans-serif;
   font-size: 1.4rem;
   font-weight: 600;
   line-height: 2.2rem;
   color: var(--blue-color);
}

#page-scheduling-request .container main .vehicles-list .instruction {
   font-family: 'Montserrat', sans-serif;
   font-size: 1.4rem;
   font-weight: 600;
   line-height: 2.1rem;
   color: var(--label);
}

#page-scheduling-request .container main .label-container {
   width: 100%;
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   padding: 0 4.5rem;
   margin-top: 4rem;
   margin-bottom: 1.1rem;
   font-family: 'Montserrat', sans-serif;
   font-size: 1rem;
   font-weight: 400;
   line-height: 1.6rem;
   color: var(--label);
}

#page-scheduling-request .container main .cards {
   width: 100%;
   height: 3rem;
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   background: var(--cards);
   border: 0.72px solid #E6E6F0;
   backdrop-filter: blur(17.1558px);
   border-radius: 0.572rem;
   padding: 0 4.5rem;
   margin-bottom: 1rem;
}

#page-scheduling-request .container main .select-chassi {
   width: 1.5rem;
}

#page-scheduling-request .container main .cards .card-click {
   width: 94%;
   height: 2.8rem;
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   font-family: 'Montserrat', sans-serif;
   font-size: 1.2rem;
   font-weight: 400;
   line-height: 1.6rem;
   color: var(--black);
   text-decoration: none;
}

#page-scheduling-request .container main .chassi-container {
   width: 15rem;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
   display: flex;
   align-items: center;
   justify-content: space-between;
}

#page-scheduling-request .container main .cards .chassi-container {
   text-transform: uppercase;
}

#page-scheduling-request .container main .date-container {
   width: 10rem;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
   display: flex;
   align-items: center;
   justify-content: space-between;
}

#page-scheduling-request .container main .model-container {
   width: 15rem;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
   display: flex;
   align-items: center;
   justify-content: space-between;
}

#page-scheduling-request .container main .cards .model-container {
   text-transform: uppercase;
}

#page-scheduling-request .container main .driver-container {
   width: 17rem;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
   display: flex;
   align-items: center;
   justify-content: space-between;
}

#page-scheduling-request .container main .cards .driver-container {
   text-transform: capitalize;
}

#page-scheduling-request .container main .plate-container {
   width: 10rem;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
   display: flex;
   align-items: center;
   justify-content: space-between;
}

#page-scheduling-request .container main .cards .plate-container {
   text-transform: uppercase;
}

#page-scheduling-request .container main .pod-container {
   width: 6rem;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
   display: flex;
   align-items: center;
   justify-content: space-between;
}

#page-scheduling-request .container main .cards .pod-container {
   text-transform: uppercase;
}

#page-scheduling-request .container main .button-filter {
   width: 2rem;
   height: 2rem;
   border: 0;
   outline: none;
   background: transparent;
   transition: opacity 0.2s;
}

#page-scheduling-request .container main .button-filter:hover {
   opacity: 0.5;
}

#page-scheduling-request .footer-container {
   position: fixed;
   bottom: 0;
   width: 100vw;
   height: 8rem;
   background: var(--white);
   display: flex;
   flex-direction: row;
   justify-content: flex-start;
   align-items: center;
}

#page-scheduling-request .footer-container .container {
   display: flex;
   flex-direction: row;
   justify-content: flex-start;
   align-items: center;
   margin: 0;
   padding: 0;
}

#page-scheduling-request .footer-container .container .button-submit {
   width: 16rem;
   height: 4.5rem;
   display: flex;
   justify-content: center;
   align-items: center;
   border-radius: 0.4rem;
   background: var(--green-color);
   font-family: 'Montserrat', sans-serif;
   font-size: 1.44rem;
   font-weight: 700;
   line-height: 1.8rem;
   color: var(--white);
   transition: background-color 0.2s;
   border: 0;
   text-decoration: none;
}

#page-scheduling-request .footer-container .container .button-submit:hover {
   background: var(--green-color-dark);
}

#page-scheduling-request .footer-container .container .button-load {
   width: 16rem;
   height: 4.5rem;
   display: flex;
   justify-content: center;
   align-items: center;
   border-radius: 0.4rem;
   background: var(--blue-color);
   font-family: 'Montserrat', sans-serif;
   font-size: 1.44rem;
   font-weight: 700;
   line-height: 1.8rem;
   color: var(--white);
   transition: background-color 0.2s;
   border: 0;
}

#page-scheduling-request .footer-container .container .button-load:hover {
   background: var(--blue-color-dark);
}

#page-scheduling-request .footer-container .container .button-load-disabled {
   width: 16rem;
   height: 4.5rem;
   display: flex;
   justify-content: center;
   align-items: center;
   border-radius: 0.4rem;
   background: var(--blue-color);
   font-family: 'Montserrat', sans-serif;
   font-size: 1.44rem;
   font-weight: 700;
   line-height: 1.8rem;
   color: var(--white);
   transition: background-color 0.2s;
   border: 0;
   cursor: default;
}

#page-scheduling-request .footer-container .container span {
   font-family: 'Montserrat', sans-serif;
   font-size: 1.6rem;
   font-weight: 400;
   line-height: 2.2rem;
   color: var(--label);
   margin-left: 3rem;
}
