#page-header-auth {
   height: 8rem;
   width: 100vw;
   display: flex;
   background: var(--white);
   flex-direction: column;
   overflow: hidden;
   border-bottom: 5px solid var(--black-grey);
}

#page-header-auth .container {
   flex: 1;
   display: flex;
   flex-direction: row;
   justify-content: flex-start;
   align-items: center;
}

#page-header-auth .container img {
   height: 3.2rem;
   width: 13.6rem;
}
