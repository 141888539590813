#buttons-navigation {
   width: 100vw;
   overflow: hidden;
   background: var(--background);
}

#buttons-navigation .container {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   padding: 2.5rem 1rem;
}

#buttons-navigation .container .button {
   width: 12.4rem;
   height: 6rem;
   background: var(--white);
   border: 1px solid rgba(0, 0, 0, 0.05);
   border-radius: 0.6rem;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   text-decoration: none;
   font-family: 'Montserrat', sans-serif;
   font-size: 1.4rem;
   line-height: 2.1rem;
   font-weight: 600;
   color: var(--label);
   text-align: center;
   transition: color 0.2s;
   transition: border 0.2s;
}

#buttons-navigation .container .button:hover {
   color: var(--blue-color);
   border-bottom: 4px solid var(--blue-color);
}

#buttons-navigation .container .button .quantity {
   font-family: 'Montserrat', sans-serif;
   font-size: 1.8rem;
   font-weight: 500;
   line-height: 2.2rem;
   color: #999FA9;
   text-align: center;
}

#buttons-navigation .container .button-selected {
   width: 12.4rem;
   height: 6rem;
   background: var(--blue-color);
   border: 1px solid rgba(0, 0, 0, 0.05);
   border-radius: 0.6rem;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   text-decoration: none;
   font-family: 'Montserrat', sans-serif;
   font-size: 1.4rem;
   line-height: 2.1rem;
   font-weight: 600;
   color: var(--white);
   text-align: center;
}

#buttons-navigation .container .button-selected .quantity-selected {
   font-family: 'Montserrat', sans-serif;
   font-size: 1.8rem;
   font-weight: 500;
   line-height: 2.2rem;
   color: var(--white);
   text-align: center;
}
