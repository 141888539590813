#input-block {
   display: flex;
   flex-direction: column;
   margin-bottom: 1rem;
}

#input-block .label-container {
   width: 23.5rem;
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: space-between;
}

#input-block .label-container-status {
   width: 16rem;
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: space-between;
}

#input-block label {
   font-family: 'Poppins', sans-serif;
   font-size: 1.2rem;
   font-weight: 400;
   line-height: 2.2rem;
   color: var(--label);
}

#input-block .button-edit {
   width: 2rem;
   height: 2rem;
   border: 0;
   background: transparent;
   display: none;
}

#input-block:hover .button-edit {
   display: block;
}

#input-block input {
   width: 23.5rem;
   height: 3.2rem;
   border-radius: 0.8rem;
   background: var(--white);
   border: 1px solid var(--border-line);
   outline: none;
   margin-top: 0.2rem;
   padding: 0 1.6rem;
   font-family: 'Montserrat', sans-serif;
   font-size: 1.2rem;
   font-weight: 400;
   line-height: 2.4rem;
   color: var(--black-grey);
   text-transform: uppercase;
}

#input-block input:disabled {
   background: var(--gray-color);
}

#input-block input:-webkit-autofill {
   -webkit-box-shadow: 0 0 0px 1000px var(--white) inset !important;
   box-shadow: 0 0 0px 1000px var(--white) inset !important;
}

#input-block input:focus {
   border: 2px solid var(--blue-color);
}
