#page-in-stock {
   flex: 1;
   width: 100vw;
   background: var(--background);
   overflow-x: hidden;
}

#page-in-stock .height {
   height: calc(100vh - 25rem);
}

#page-in-stock h2 {
   font-family: 'Montserrat', sans-serif;
   font-size: 1.4rem;
   font-weight: 600;
   line-height: 2.1rem;
   color: var(--label);
   margin-right: 2.3rem;
}

#page-in-stock h3 {
   font-family: 'Montserrat', sans-serif;
   font-size: 1.4rem;
   font-weight: 600;
   line-height: 2.1rem;
   color: var(--label);
   margin: 0;
}

#page-in-stock .header {
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: flex-start;
   margin: 2.3rem 0;
}

#page-in-stock .header .date-container {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
}

#page-in-stock .header .date-container .date-input {
   width: 16rem;
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
}

#page-in-stock .header .date-container .button-date {
   width: 16rem;
   height: 3.2rem;
   display: flex;
   flex-direction: row;
   justify-content: flex-start;
   align-items: center;
   border-radius: 0.8rem;
   border: 1px solid var(--border-line);
   background-color: var(--white);
   padding: 0 1.6rem;
}

#page-in-stock .header .date-container .button-date .placeholder {
   font-family: 'Montserrat', sans-serif;
   font-size: 1.2rem;
   font-weight: 400;
   line-height: 2.4rem;
   color: var(--label-secondary);
}

#page-in-stock .header .date-container .button-date .date-selected {
   font-family: 'Montserrat', sans-serif;
   font-size: 1.2rem;
   font-weight: 400;
   line-height: 2.4rem;
   color: var(--label);
}

#page-in-stock .header .label-container {
   width: 16rem;
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: flex-start;
}

#page-in-stock .header .label-container label {
   font-family: 'Poppins', sans-serif;
   font-size: 1.2rem;
   font-weight: 400;
   line-height: 2.2rem;
   color: #32264C;
}

#page-in-stock .chart-container-line {
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: space-between;
}

#page-in-stock .in-block {
   height: 25.2rem;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: space-between;
}

#page-in-stock .info-container {
   width: 26rem;
   height: 11.6rem;
   background: var(--white);
   border: 1px solid var(--border-line);
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
}

#page-in-stock .info-container span {
   font-family: 'Montserrat', sans-serif;
   font-size: 5.4rem;
   font-weight: 600;
   color: var(--black);
}

#page-in-stock .info-container-small {
   width: 26rem;
   height: 5.4rem;
   background: var(--white);
   border: 1px solid var(--border-line);
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: space-between;
   padding: 0 3.5rem;
}

#page-in-stock .info-container-small span {
   width: 10rem;
   font-family: 'Montserrat', sans-serif;
   font-size: 3.6rem;
   font-weight: 600;
   color: var(--black);
   text-align: right;
}

#page-in-stock .info-container-small h3 {
   width: 7.5rem;
   text-align: left;
}

#page-in-stock .chart-container-small {
   width: 53rem;
   height: 25.2rem;
   background: var(--white);
   border: 1px solid var(--border-line);
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: space-between;
   padding-top: 1.5rem;
}

#page-in-stock .chart-container-small h3 {
   width: 100%;
   padding: 0 2.7rem;
}

#page-in-stock .chart-container-small button {
   width: 14rem;
   height: 3rem;
   background: var(--white);
   font-family: 'Montserrat', sans-serif;
   font-size: 1.2rem;
   font-weight: 600;
   line-height: 1.8rem;
   color: var(--label);
   cursor: pointer;
   border: 0;
   outline: none;
}

#page-in-stock .chart-container-small .btn-not-assigned {
   border: 1.5px solid var(--black);
   border-radius: 1.7rem 0 0 1.7rem;
}

#page-in-stock .chart-container-small .btn-ipc {
   border-top: 1.5px solid var(--black);
   border-bottom: 1.5px solid var(--black);
}

#page-in-stock .chart-container-small .btn-short-sea {
   border: 1.5px solid var(--black);
   border-radius: 0 1.7rem 1.7rem 0;
}

#page-in-stock .chart-container-small .selected {
   color: var(--white);
   background-color: var(--label-secondary);
   border: 0;
}
