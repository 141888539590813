#page-loaded-dashboard {
   flex: 1;
   width: 100vw;
   background: var(--background);
   overflow-x: hidden;
}

#page-loaded-dashboard .height {
   height: calc(100vh - 25rem);
}

#page-loaded-dashboard h2 {
   font-family: 'Montserrat', sans-serif;
   font-size: 1.4rem;
   font-weight: 600;
   line-height: 2.1rem;
   color: var(--label);
   margin-right: 2.3rem;
}

#page-loaded-dashboard h3 {
   font-family: 'Montserrat', sans-serif;
   font-size: 1.4rem;
   font-weight: 600;
   line-height: 2.1rem;
   color: var(--label);
   margin: 0;
}

#page-loaded-dashboard .header {
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: flex-start;
   margin: 2.3rem 0;
}

#page-loaded-dashboard .header .date-container {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
}

#page-loaded-dashboard .header .date-container .date-input {
   width: 16rem;
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
}

#page-loaded-dashboard .header .date-container .button-date {
   width: 16rem;
   height: 3.2rem;
   display: flex;
   flex-direction: row;
   justify-content: flex-start;
   align-items: center;
   border-radius: 0.8rem;
   border: 1px solid var(--border-line);
   background-color: var(--white);
   padding: 0 1.6rem;
}

#page-loaded-dashboard .header .date-container .button-date .placeholder {
   font-family: 'Montserrat', sans-serif;
   font-size: 1.2rem;
   font-weight: 400;
   line-height: 2.4rem;
   color: var(--label-secondary);
}

#page-loaded-dashboard .header .date-container .button-date .date-selected {
   font-family: 'Montserrat', sans-serif;
   font-size: 1.2rem;
   font-weight: 400;
   line-height: 2.4rem;
   color: var(--label);
}

#page-loaded-dashboard .header .label-container {
   width: 16rem;
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: flex-start;
}

#page-loaded-dashboard .header .label-container label {
   font-family: 'Poppins', sans-serif;
   font-size: 1.2rem;
   font-weight: 400;
   line-height: 2.2rem;
   color: #32264C;
}

#page-loaded-dashboard .chart-container-line {
   width: 54rem;
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: space-between;
   margin-bottom: 1rem;
}

#page-loaded-dashboard .in-block {
   height: 11.6rem;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: space-between;
}

#page-loaded-dashboard .info-container {
   width: 26rem;
   height: 11.6rem;
   background: var(--white);
   border: 1px solid var(--border-line);
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
}

#page-loaded-dashboard .info-container span {
   font-family: 'Montserrat', sans-serif;
   font-size: 5.4rem;
   font-weight: 600;
   color: var(--black);
}

#page-loaded-dashboard .info-container-small {
   width: 26rem;
   height: 5.4rem;
   background: var(--white);
   border: 1px solid var(--border-line);
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: space-between;
   padding: 0 3.5rem;
}

#page-loaded-dashboard .info-container-small span {
   width: 10rem;
   font-family: 'Montserrat', sans-serif;
   font-size: 3.6rem;
   font-weight: 600;
   color: var(--black);
   text-align: right;
}

#page-loaded-dashboard .info-container-small h3 {
   width: 7.5rem;
   text-align: left;
}

#page-loaded-dashboard .chart-container-big {
   width: 100%;
   height: 25.2rem;
   background: var(--white);
   border: 1px solid var(--border-line);
   display: flex;
   padding: 1.4rem 2.7rem;
}

#page-loaded-dashboard .chart-container-small {
   width: 100%;
   display: flex;
   flex-direction: column;
   padding-top: 1.4rem;
   overflow: hidden;
}
