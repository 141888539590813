#page-inTransit-list {
   flex: 1;
   width: 100vw;
   background: var(--background);
   overflow-x: hidden;
}

#page-inTransit-list .container {
   height: calc(100vh - 12.5rem);
}

#page-inTransit-list .container .header-title {
   margin-top: 4.5rem;
}

#page-inTransit-list .container .header-title h1 {
   font-family: 'Montserrat', sans-serif;
   font-size: 3.6rem;
   font-weight: 600;
   line-height: 4.4rem;
   color: var(--black);
   margin-bottom: 1.8rem;
}

#page-inTransit-list .container .header-title span {
   font-family: 'Montserrat', sans-serif;
   font-size: 1.4rem;
   font-weight: 600;
   line-height: 2.1rem;
   color: var(--label);
}

#page-inTransit-list .container main {
   margin-top: 6rem;
   padding-bottom: 4rem;
   z-index: 0;
}

#page-inTransit-list .container .button-submit {
   width: 30rem;
   height: 8rem;
   display: flex;
   justify-content: center;
   align-items: center;
   border-radius: 0.4rem;
   background: var(--green-color);
   font-family: 'Montserrat', sans-serif;
   font-size: 2.6rem;
   font-weight: 700;
   line-height: 3.2rem;
   color: var(--white);
   transition: background-color 0.2s;
   border: 0;
   text-decoration: none;
}

#page-inTransit-list .container .button-submit:hover {
   background: var(--green-color-dark);
}

#page-inTransit-list .container .button-load {
   width: 16rem;
   height: 4.5rem;
   display: flex;
   justify-content: center;
   align-items: center;
   border-radius: 0.4rem;
   background: var(--blue-color);
   font-family: 'Montserrat', sans-serif;
   font-size: 1.44rem;
   font-weight: 700;
   line-height: 1.8rem;
   color: var(--white);
   transition: background-color 0.2s;
   border: 0;
}

#page-inTransit-list .container .button-load:hover {
   background: var(--blue-color-dark);
}

#page-inTransit-list .container .button-load-disabled {
   width: 16rem;
   height: 4.5rem;
   display: flex;
   justify-content: center;
   align-items: center;
   border-radius: 0.4rem;
   background: var(--blue-color);
   font-family: 'Montserrat', sans-serif;
   font-size: 1.44rem;
   font-weight: 700;
   line-height: 1.8rem;
   color: var(--white);
   transition: background-color 0.2s;
   border: 0;
   cursor: default;
}
