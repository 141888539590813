#page-vessel-edit {
   width: 100vw;
   height: 100vh;
   background: var(--background);
   overflow: auto;
   overflow-x: hidden;
}

#page-vessel-edit .content {
   height: calc(100% - 8rem);
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   margin-top: 4.5rem;
}

#page-vessel-edit .content .header {
   width: 100%;
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   margin-bottom: 4.1rem;
}

#page-vessel-edit .content h1 {
   font-family: 'Montserrat', sans-serif;
   font-size: 3.6rem;
   font-weight: 600;
   line-height: 4.4rem;
   color: var(--black-grey);
}

#page-vessel-edit .content span {
   font-family: 'Montserrat', sans-serif;
   font-size: 1.2rem;
   font-weight: 300;
   line-height: 2.2rem;
   color: var(--black-grey);
}

#page-vessel-edit .content main .input-container {
   display: flex;
   flex-direction: row;
   justify-content: flex-start;
   align-items: flex-start;
   margin: 3.2rem auto 0;
}

#page-vessel-new .content main .input-container+.input-container {
   margin: 2rem auto;
}

#page-vessel-edit .content main .input-container .input-block {
   position: relative;
   display: flex;
   flex-direction: column;
   margin: 0 1rem 0 0;
}

#page-vessel-edit .content main .input-container .input-block+.input-block {
   margin: auto 1rem;
}

#page-vessel-edit .content main .input-container .input-block label {
   font-family: 'Poppins', sans-serif;
   font-size: 1.2rem;
   font-weight: 400;
   line-height: 2.2rem;
   color: var(--black-grey);
   margin-bottom: 0;
}

#page-vessel-edit .content main .input-container .input-block span {
   font-family: 'Poppins', sans-serif;
   font-size: 1.2rem;
   font-weight: 400;
   line-height: 2.2rem;
   color: var(--red-color);
   padding-left: 6rem;
}

#page-vessel-edit .content main .input-container .input-block input {
   width: 20.4rem;
   height: 3.2rem;
   border: 1px solid var(--border-line);
}

#page-vessel-edit .content main .input-container .input-block input[disabled] {
   background: var(--border-line);
   border: 1px solid var(--border-line);
   color: #808080;
}

#page-vessel-edit .content main .buttons-container {
   display: flex;
   flex-direction: row;
   justify-content: flex-start;
   align-items: center;
   margin-top: 4rem;
}

#page-vessel-edit .content main .buttons-container .cancel-button {
   width: 12rem;
   height: 4.5rem;
   display: flex;
   background-color: transparent;
   justify-content: center;
   align-items: center;
   border: 1px solid var(--black-grey);
   border-radius: 0.4rem;
   font-family: 'Montserrat', sans-serif;
   font-size: 1.44rem;
   font-weight: 400;
   line-height: 1.8rem;
   color: var(--black-grey);
   transition: opacity 0.2s;
   margin-right: 2rem;
   text-decoration: none;
}

#page-vessel-edit .content main .buttons-container .cancel-button:hover {
   opacity: 0.5;
}

#page-vessel-edit .content main .buttons-container .submit-button {
   width: 12rem;
   height: 4.5rem;
   background-color: var(--blue-color);
   border-radius: 0.4rem;
   display: flex;
   justify-content: center;
   align-items: center;
   font-family: 'Montserrat', sans-serif;
   font-size: 1.44rem;
   font-weight: 700;
   line-height: 1.8rem;
   color: var(--white);
   border: 0;
   transition: background-color 0.2s;
}

#page-vessel-edit .content main .buttons-container .submit-button:hover {
   background-color: var(--blue-color-dark);
}
