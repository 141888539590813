#page-pdi {
   width: 100vw;
   height: 100vh;
   background: var(--background);
   overflow: auto;
   overflow-x: hidden;
}

#page-pdi .content {
   height: calc(100% - 8rem);
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   margin-top: 4.5rem;
}

#page-pdi .content .header {
   width: 100%;
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   margin-bottom: 4.1rem;
}

#page-pdi .content h1 {
   font-family: 'Montserrat', sans-serif;
   font-size: 3.6rem;
   font-weight: 600;
   line-height: 4.4rem;
   color: var(--black-grey);
}

#page-pdi .content span {
   font-family: 'Montserrat', sans-serif;
   font-size: 1.2rem;
   font-weight: 300;
   line-height: 2.2rem;
   color: var(--black-grey);
}

#page-pdi .content .no-pdi-container {
   display: flex;
   flex-direction: column;
   margin-left: 3rem;
}

#page-pdi .content .no-pdi-container span {
   font-family: 'Montserrat', sans-serif;
   font-size: 1.4rem;
   font-weight: 600;
   line-height: 2.2rem;
   color: var(--blue-color);
   margin-bottom: 3rem;
}

#page-pdi .content .pdi-container {
   width: 100%;
   height: 100%;
   margin-bottom: 2rem;
}

#page-pdi .content .pdi-container .label-container {
   width: 100%;
   margin-bottom: 1.5rem;
   padding: 0 1.6rem;
}

#page-pdi .content .pdi-container .label-container label {
   font-family: 'Montserrat', sans-serif;
   font-size: 1.4rem;
   font-weight: 400;
   line-height: 2.2rem;
   color: #5D5D5D;
}

#page-pdi .content .pdi-container .label-container .label-service {
   width: 35rem;
}

#page-pdi .content .pdi-container .label-container .label-cost {
   width: 18rem;
}

#page-pdi .content .pdi-container .pdi {
   width: 100%;
   height: 3.4rem;
   background: var(--white);
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: space-between;
   margin-bottom: 1rem;
   border: 1px solid var(--border-line);
   backdrop-filter: blur(24px);
   border-radius: 0.8rem;
   padding: 0 1.5rem;
}

#page-pdi .content .pdi-container .pdi .pdi-info {
   display: flex;
   flex-direction: row;
   justify-content: flex-start;
   align-items: center;
   font-family: 'Montserrat', sans-serif;
   font-size: 1.4rem;
   font-weight: 400;
   line-height: 2.2rem;
   color: var(--black-grey);
}

#page-pdi .content .pdi-container .pdi .pdi-info .pdi-service {
   width: 35rem;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
}

#page-pdi .content .pdi-container .pdi .pdi-info .pdi-cost {
   width: 18rem;
}

#page-pdi .content .pdi-container .pdi .pdi-buttons {
   width: 2rem;
   display: flex;
   justify-content: space-between;
}

#page-pdi .content .pdi-container .pdi .pdi-buttons .icons {
   cursor: pointer;
   border: 0;
   outline: none;
}

#page-pdi .content .pdi-container .pdi .pdi-buttons .icons:hover {
   opacity: 0.5;
}

#page-pdi .content .button-container {
   display: flex;
   flex-direction: row;
   justify-content: flex-start;
   align-items: center;
}

#page-pdi .content .button-container .add-new-button {
   width: 12rem;
   height: 4.5rem;
   background-color: var(--blue-color);
   border-radius: 0.4rem;
   display: flex;
   justify-content: center;
   align-items: center;
   font-family: 'Montserrat', sans-serif;
   font-size: 1.44rem;
   font-weight: 600;
   line-height: 1.8rem;
   color: var(--white);
   border: 0;
   transition: background-color 0.2s;
   text-decoration: none;
}

#page-pdi .content .button-container .back-button {
   width: 12rem;
   height: 4.5rem;
   border: 1px solid var(--black-grey);
   border-radius: 0.4rem;
   display: flex;
   justify-content: center;
   align-items: center;
   background-color: transparent;
   font-family: 'Montserrat', sans-serif;
   font-size: 1.44rem;
   font-weight: 400;
   line-height: 1.8rem;
   color: var(--black-grey);
   margin-left: 2rem;
   text-decoration: none;
   transition: opacity 0.2s;
}

#page-pdi .content .button-container .back-button:hover {
   opacity: 0.6;
}

#page-pdi .content .add-new-button:hover {
   background-color: var(--blue-color-dark);
}
