#links-navigation-dashboard {
   width: 100vw;
   height: 13.4rem;
   overflow: hidden;
   background: var(--white);
   display: flex;
   border: 1px solid #EDEDED;
}

#links-navigation-dashboard .container {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: flex-start;
}

#links-navigation-dashboard .container h1 {
   font-family: 'Montserrat', sans-serif;
   font-size: 3.6rem;
   font-weight: 600;
   color: var(--black);
   margin-top: 4rem;
   margin-bottom: 2rem;
}

#links-navigation-dashboard .container .links-container {
   width: 100%;
   display: flex;
   flex-direction: row;
   justify-content: flex-start;
   align-items: center;
}

#links-navigation-dashboard .container .links-container a {
   width: 13rem;
   text-decoration: none;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}

#links-navigation-dashboard .container .links-container .link {
   font-family: 'Montserrat', sans-serif;
   font-weight: 600;
   font-size: 1.2rem;
   line-height: 1.8rem;
   color: #808080;
   text-align: center;
   text-transform: uppercase;
   margin-bottom: 0.5rem;
}

#links-navigation-dashboard .container .links-container .link:hover {
   color: var(--black);
}

#links-navigation-dashboard .container .links-container .link-selected {
   font-family: 'Montserrat', sans-serif;
   font-weight: 600;
   font-size: 1.2rem;
   line-height: 1.8rem;
   color: #5D5D5D;
   text-align: center;
   text-transform: uppercase;
   margin-bottom: 0.5rem;
}

#links-navigation-dashboard .container .links-container .link-selected:hover {
   color: var(--black);
}

#links-navigation-dashboard .container .links-container .line {
   height: 0.3rem;
   width: 100%;
   background: var(--white);
}

#links-navigation-dashboard .container .links-container .line-selected {
   height: 0.3rem;
   width: 100%;
   background: var(--red-color);
}
