#page-yard-capacity {
   flex: 1;
   width: 100vw;
   background: var(--background);
   overflow-x: hidden;
}

#page-yard-capacity .height {
   height: calc(100vh - 25rem);
}

#page-yard-capacity h2 {
   font-family: 'Montserrat', sans-serif;
   font-size: 1.4rem;
   font-weight: 600;
   line-height: 2.1rem;
   color: var(--label);
   margin: 3.2rem 0 2.1rem;
}

#page-yard-capacity h3 {
   font-family: 'Montserrat', sans-serif;
   font-size: 1.4rem;
   font-weight: 600;
   line-height: 2.1rem;
   color: var(--label);
   margin: 0;
}

#page-yard-capacity .header {
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: flex-start;
   margin: 2.3rem 0;
}

#page-yard-capacity .header .date-container {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   margin-left: 2rem;
}

#page-yard-capacity .header .date-container .date-input {
   width: 25.6rem;
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
}

#page-yard-capacity .header .date-container .button-date {
   width: 11.8rem;
   height: 3.2rem;
   display: flex;
   flex-direction: row;
   justify-content: flex-start;
   align-items: center;
   border-radius: 0.8rem;
   border: 1px solid var(--border-line);
   background-color: var(--white);
   padding: 0 1.6rem;
}

#page-yard-capacity .header .date-container .button-date .placeholder {
   font-family: 'Montserrat', sans-serif;
   font-size: 1.2rem;
   font-weight: 400;
   line-height: 2.4rem;
   color: var(--label-secondary);
}

#page-yard-capacity .header .date-container .button-date .date-selected {
   font-family: 'Montserrat', sans-serif;
   font-size: 1.2rem;
   font-weight: 400;
   line-height: 2.4rem;
   color: var(--label);
}

#page-yard-capacity .header .label-container {
   width: 25.6rem;
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: flex-start;
}

#page-yard-capacity .header .label-container label {
   font-family: 'Poppins', sans-serif;
   font-size: 1.2rem;
   font-weight: 400;
   line-height: 2.2rem;
   color: #32264C;
}

#page-yard-capacity .chart-container-big {
   width: 100%;
   height: 35rem;
   background: var(--white);
   border: 1px solid var(--border-line);
   display: flex;
   flex-direction: column;
   padding: 1.4rem 2.7rem;
   overflow: hidden;
}

#page-yard-capacity .chart-container-big span {
   font-family: 'Montserrat', sans-serif;
   font-size: 1.4rem;
   line-height: 1.8rem;
   color: #000;
   margin: 3rem;
}
