.dropdown-filter {
   position: relative;
   display: inline-block;
}

.dropdown-filter .button-filter {
   width: 2rem;
   height: 2rem;
   border: 0;
   outline: none;
   background: transparent;
   transition: opacity 0.2s;
}

.dropdown-filter .button-filter:hover {
   opacity: 0.5;
}

.dropdown-filter .menu-select {
   position: absolute;
   display: block;
   z-index: 99;
   top: 3rem;
   right: 0;
}

.dropdown-filter .select-container-filter {
   width: 27.4rem;
   height: 8.2rem;
   background: var(--label);
   border-radius: 0.6rem;
   border: 1px solid rgba(0, 0, 0, 0.05);
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: flex-start;
   padding: 0 1.4rem;
   position: relative;
}

.dropdown-filter .select-container-filter h1 {
   font-family: 'Poppins', sans-serif;
   font-size: 1.2rem;
   font-weight: 400;
   line-height: 2.2rem;
   color: #FFF;
   margin: 0;
}

.dropdown-filter .select-container-filter .filter {
   width: 100%;
   height: 4rem;
   display: flex;
   flex-direction: row;
   justify-content: space-evenly;
   align-items: flex-end;
}

.dropdown-filter .select-container-filter .filter .input-block {
   margin: 0;
}

.dropdown-filter .select-container-filter .filter .input-block input {
   width: 20.4rem;
   height: 3.2rem;
   font-size: 1.2rem;
   margin: 0;
}

.dropdown-filter .select-container-filter .filter .button-search {
   width: 3.2rem;
   height: 3.2rem;
   background-color: var(--green-color);
   border-radius: 0.8rem;
   display: flex;
   justify-content: center;
   align-items: center;
   margin-left: 1rem;
   border: 0;
   transition: background-color 0.2s;
}

.dropdown-filter .select-container-filter .filter .button-search:hover {
   background-color: var(--green-color-dark);
}

.dropdown-filter .select-container-filter .filter .yes-button {
   width: 10rem;
   height: 3.2rem;
   background-color: var(--green-color);
   border-radius: 0.8rem;
   display: flex;
   justify-content: center;
   align-items: center;
   font-family: 'Montserrat', sans-serif;
   font-size: 1.2rem;
   font-weight: 400;
   color: var(--black);
   border: 0;
   transition: background-color 0.2s;
}

.dropdown-filter .select-container-filter .filter .yes-button:hover {
   background-color: var(--green-color-dark);
}

.dropdown-filter .select-container-filter .filter .no-button {
   width: 10rem;
   height: 3.2rem;
   background-color: var(--red-color);
   border-radius: 0.8rem;
   display: flex;
   justify-content: center;
   align-items: center;
   font-family: 'Montserrat', sans-serif;
   font-size: 1.2rem;
   font-weight: 400;
   color: var(--white);
   border: 0;
   transition: background-color 0.2s;
}

.dropdown-filter .select-container-filter .filter .no-button:hover {
   background-color: var(--red-color-dark);
}

.dropdown-filter .menu-select-options {
   position: absolute;
   display: block;
   z-index: 99;
   top: 8.1rem;
   left: 0;
   border: 1px solid rgba(0, 0, 0, 0.05);
   box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.1);
   border-radius: 0.6rem;
   background-color: var(--white);
}

.dropdown-filter .menu-select-options .options-container {
   width: 27rem;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: flex-start;
   border: 0;
   background-color: transparent;
   font-family: 'Montserrat', sans-serif;
   font-size: 1.2rem;
   font-weight: 400;
   line-height: 2.4rem;
   color: #32264C;
   padding: 0.5rem 2.6rem;
   transition: background-color 0.2s;
   transition: color 0s;
   text-transform: uppercase;
}

.dropdown-filter .menu-select-options .options-container:hover {
   background-color: var(--blue-color);
   color: var(--white);
   border-radius: 0.6rem;
}
