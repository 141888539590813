#chassi-details-header {
   width: 100vw;
   height: 4rem;
   background-color: #868785;
   display: flex;
}

#chassi-details-header .container {
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: flex-start;
}

#chassi-details-header .container .chassi-label {
   font-family: 'Montserrat', sans-serif;
   font-size: 1.2rem;
   font-weight: 300;
   line-height: 1.8rem;
   color: var(--white);
   margin-right: 1.3rem;
}

#chassi-details-header .container .chassi-number {
   font-family: 'Montserrat', sans-serif;
   font-size: 1.8rem;
   font-weight: 600;
   line-height: 2.2rem;
   color: var(--white);
   text-transform: uppercase;
   margin-right: 4.1rem;
}

#chassi-details-header .container .model-name {
   font-family: 'Montserrat', sans-serif;
   font-size: 1.2rem;
   font-weight: 300;
   line-height: 1.8rem;
   color: var(--white);
   text-transform: uppercase;
   margin-left: 0.8rem;
   margin-right: 1rem;
}

#chassi-details-header .container .model-year {
   font-family: 'Montserrat', sans-serif;
   font-size: 1.2rem;
   font-weight: 300;
   line-height: 1.8rem;
   color: var(--white);
   text-transform: uppercase;
   margin-right: 4rem;
}

#chassi-details-header .container .pod {
   font-family: 'Montserrat', sans-serif;
   font-size: 1.2rem;
   font-weight: 300;
   line-height: 1.8rem;
   color: var(--white);
   text-transform: uppercase;
   margin-left: 0.8rem;
   margin-right: 4rem;
}

#chassi-details-header .container .status-container {
   height: 2rem;
   background-color: var(--white);
   border-radius: 0.3rem;
   filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
   padding: 1.2rem 1rem;
   display: flex;
   justify-content: center;
   align-items: center;
   font-family: 'Montserrat', sans-serif;
   font-size: 1rem;
   font-weight: 500;
   line-height: 1.6rem;
   color: var(--blue-color);
   text-transform: capitalize;
}
