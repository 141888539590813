#modal-add-folio {
   display: flex;
   justify-content: center;
   align-items: center;
}

#modal-add-folio .modal-content {
   width: 60rem;
   border-radius: 2rem;
}

#modal-add-folio .modal-content .modal-body {
   width: 60rem;
   height: 52rem;
   background: rgba(255, 255, 255, 0.9);
   box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
   backdrop-filter: blur(24px);
   display: flex;
   flex-direction: column;
   border-radius: 2rem;
   padding: 5.5rem;
}

#modal-add-folio .modal-content .modal-body h1 {
   font-family: 'Montserrat', sans-serif;
   font-size: 3.6rem;
   font-weight: 600;
   line-height: 4.4rem;
   color: var(--black);
}

#modal-add-folio .modal-content .modal-body span {
   font-family: 'Montserrat', sans-serif;
   font-size: 1.2rem;
   font-weight: 300;
   line-height: 2.2rem;
   color: var(--black);
}

#modal-add-folio .modal-content .modal-body .label-container {
   width: 100%;
   display: flex;
   flex-direction: row;
   justify-content: flex-start;
   align-items: center;
   margin-top: 4rem;
   margin-bottom: 1rem;
   padding: 0;
}

#modal-add-folio .modal-content .modal-body .label-container label {
   font-family: 'Montserrat', sans-serif;
   font-size: 1rem;
   font-weight: 400;
   line-height: 1.6rem;
   color: var(--label);
}

#modal-add-folio .modal-content .modal-body .label-container .chassis-label {
   width: 19.5rem;
}

#modal-add-folio .modal-content .modal-body .label-container .model-label {
   width: 21rem;
}

#modal-add-folio .modal-content .modal-body .label-container .pod-label {
   width: 8.5rem;
}

#modal-add-folio .modal-content .modal-body .items-container {
   width: 100%;
   padding-bottom: 1rem;
   margin-bottom: 4rem;
}

#modal-add-folio .modal-content .modal-body .items-container .row {
   width: 100%;
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   margin-bottom: 1rem;
   padding-left: 2rem;
   padding-right: 1.5rem;
}

#modal-add-folio .modal-content .modal-body .items-container .row span {
   font-family: 'Montserrat', sans-serif;
   font-size: 1.2rem;
   font-weight: 400;
   line-height: 1.6rem;
   color: var(--black);
   justify-self: start;
}

#modal-add-folio .modal-content .modal-body .items-container .row .chassis {
   width: 15rem;
   text-transform: uppercase;
}

#modal-add-folio .modal-content .modal-body .items-container .row .model {
   width: 17rem;
   text-transform: uppercase;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
}

#modal-add-folio .modal-content .modal-body .items-container .row .pod {
   width: 5.5rem;
   text-transform: uppercase;
}

#modal-add-folio .modal-content .modal-body .folio-input-container {
   margin-bottom: 2.4rem;
}

#modal-add-folio .modal-content .modal-body .folio-input-container .input-block {
   margin: 0;
}

#modal-add-folio .modal-content .modal-body .folio-input-container .input-block input {
   width: 20.4rem;
   height: 3.2rem;
   margin-top: 0.4rem;
}

#modal-add-folio .modal-content .modal-body .folio-input-container .input-block:focus-within::after {
   width: calc(20.4rem - 3.2rem);
   height: 1px;
   content: '';
   background: var(--blue-color);
   position: absolute;
   left: 1.6rem;
   right: 1.6rem;
   bottom: 0.7rem;
}

#modal-add-folio .modal-content .modal-body .folio-input-container .input-block label {
   color: #32264C;
}

#modal-add-folio .modal-content .modal-body .buttons.container {
   display: flex;
   flex-direction: row;
   justify-content: flex-start;
   align-items: center;
}

#modal-add-folio .modal-content .modal-body .buttons-container .button-cancel {
   width: 12rem;
   height: 4.5rem;
   border-radius: 0.4rem;
   border: 1px solid var(--black);
   background: transparent;
   font-family: 'Montserrat', sans-serif;
   font-size: 1.44rem;
   font-weight: 400;
   line-height: 1.8rem;
   color: var(--black);
   transition: opacity 0.2s;
}

#modal-add-folio .modal-content .modal-body .buttons-container .button-cancel:hover {
   opacity: 0.5;
}

#modal-add-folio .modal-content .modal-body .buttons-container .button-save {
   width: 12rem;
   height: 4.5rem;
   border-radius: 0.4rem;
   border: 0;
   background-color: var(--blue-color);
   font-family: 'Montserrat', sans-serif;
   font-size: 1.44rem;
   font-weight: 700;
   line-height: 1.8rem;
   color: var(--white);
   transition: background-color 0.2s;
   margin-left: 2rem;
}

#modal-add-folio .modal-content .modal-body .buttons-container .button-save-disabled {
   width: 12rem;
   height: 4.5rem;
   border-radius: 0.4rem;
   border: 0;
   background-color: var(--blue-color);
   font-family: 'Montserrat', sans-serif;
   font-size: 1.44rem;
   font-weight: 700;
   line-height: 1.8rem;
   color: var(--white);
   transition: background-color 0.2s;
   margin-left: 2rem;
   cursor: default;
}

#modal-add-folio .modal-content .modal-body .buttons-container .button-save:hover {
   background-color: var(--blue-color-dark);
}
