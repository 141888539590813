#dropdown-page-header {
   position: relative;
   display: inline-block;
}

#dropdown-page-header .menu-select {
   position: absolute;
   display: block;
   z-index: 1;
   top: 4.3rem;
   left: 0;
}

#dropdown-page-header .select-container-register {
   width: 18.5rem;
   background: var(--white);
   border-radius: 0 0 0.4rem 0.4rem;
   border-left: 2px solid #AAAAAA;
   border-right: 2px solid #AAAAAA;
   border-bottom: 2px solid #AAAAAA;
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   align-items: flex-start;
}

#dropdown-page-header .select-container-report {
   width: 18.5rem;
   background: var(--white);
   border-radius: 0 0 0.4rem 0.4rem;
   border-top: 2px solid var(--blue-color);
   border-left: 2px solid #AAAAAA;
   border-right: 2px solid #AAAAAA;
   border-bottom: 2px solid #AAAAAA;
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   align-items: flex-start;
}

#dropdown-page-header .select-container-register .title, #dropdown-page-header .select-container-report .title {
   width: 100%;
   height: 3.6rem;
   display: flex;
   align-items: center;
   justify-content: flex-start;
   margin-top: 0.51rem;
   padding: 0 1.6rem;
}

#dropdown-page-header .select-container-register .title h3, #dropdown-page-header .select-container-report .title h3 {
   font-family: 'Montserrat', sans-serif;
   font-size: 1.6rem;
   font-weight: 600;
   line-height: 2rem;
   color: #111111;
}

#dropdown-page-header .select-container-register button, #dropdown-page-header .select-container-report button {
   width: 100%;
   height: 3.6rem;
   display: flex;
   align-items: center;
   justify-content: flex-start;
   padding: 0 1rem;
   font-family: 'Montserrat', sans-serif;
   font-size: 1.4rem;
   font-weight: 400;
   line-height: 2rem;
   color: #111111;
   background-color: var(--white);
   border: 0;
   transition: background-color 0.2s;
   transition: color 0s;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
}

#dropdown-page-header .select-container-register a, #dropdown-page-header .select-container-report a {
   width: 100%;
   height: 3.6rem;
   display: flex;
   align-items: center;
   justify-content: flex-start;
   padding: 0 1rem;
   font-family: 'Montserrat', sans-serif;
   font-size: 1.4rem;
   font-weight: 400;
   line-height: 2rem;
   color: #111111;
   background-color: var(--white);
   border: 0;
   transition: background-color 0.2s;
   transition: color 0s;
   text-decoration: none;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
}

#dropdown-page-header .select-container-register .button-add-pdi {
   width: 100%;
   height: 3.6rem;
   display: flex;
   align-items: center;
   justify-content: flex-start;
   padding: 0 1rem;
   font-family: 'Montserrat', sans-serif;
   font-size: 1.4rem;
   font-weight: 600;
   line-height: 2rem;
   color: var(--black);
   background-color: var(--white);
   border: 0;
   transition: background-color 0.2s;
}

#dropdown-page-header .select-container-register button:hover, #dropdown-page-header .select-container-report button:hover {
   background-color: var(--blue-color);
   border-radius: 0.4rem;
   color: var(--white);
}

#dropdown-page-header .select-container-register a:hover, #dropdown-page-header .select-container-report a:hover {
   background-color: var(--blue-color);
   border-radius: 0.4rem;
   color: var(--white);
}
