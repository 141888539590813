#page-details-tracking {
   background: var(--background);
   overflow: hidden;
}

#page-details-tracking .container {
   width: 100%;
   height: calc(100vh - 18rem);
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
   margin-top: 2rem;
}

#page-details-tracking .container .container-left {
   width: 100%;
   height: 100%;
   display: flex;
   flex: 1;
   margin-right: 0.5rem;
}

#page-details-tracking .container .container-right {
   width: 100%;
   height: 100%;
   display: flex;
   flex: 1;
   flex-direction: column;
   margin-left: 0.5rem;
   position: relative;
}

#page-details-tracking .container .container-right h2 {
   font-family: 'Montserrat', sans-serif;
   font-size: 1.4rem;
   font-weight: 600;
   line-height: 2.1rem;
   color: var(--label);
   margin-bottom: 2rem;
}

#page-details-tracking .container .container-right .line {
   position: absolute;
   top: 5.5rem;
   left: 2.55rem;
   height: 45.5rem;
   width: 0.1rem;
   background: var(--black);
}

#page-details-tracking .container .container-right .line-first {
   position: absolute;
   top: 6.6rem;
   left: 2.55rem;
   height: 6rem;
   width: 0.1rem;
   background: var(--black);
}

#page-details-tracking .container .container-right .line-second {
   position: absolute;
   top: 13.2rem;
   left: 2.55rem;
   height: 6rem;
   width: 0.1rem;
   background: var(--black);
}

#page-details-tracking .container .container-right .line-third {
   position: absolute;
   top: 19.7rem;
   left: 2.55rem;
   height: 6rem;
   width: 0.1rem;
   background: var(--black);
}

#page-details-tracking .container .container-right .line-fourth {
   position: absolute;
   top: 26rem;
   left: 2.55rem;
   height: 6rem;
   width: 0.1rem;
   background: var(--black);
}

#page-details-tracking .container .container-right .line-fifth {
   position: absolute;
   top: 32.5rem;
   left: 2.55rem;
   height: 6rem;
   width: 0.1rem;
   background: var(--black);
}

#page-details-tracking .container .container-right .line-sixth {
   position: absolute;
   top: 39rem;
   left: 2.55rem;
   height: 6rem;
   width: 0.1rem;
   background: var(--black);
}

#page-details-tracking .container .container-right .line-seventh {
   position: absolute;
   top: 45rem;
   left: 2.55rem;
   height: 6rem;
   width: 0.1rem;
   background: var(--black);
}
