#tracking-container {
   display: flex;
   flex-direction: row;
   align-items: center;
   margin-left: 1.8rem;
   margin-bottom: 2.6rem
}

#tracking-container .circle {
   width: 1.7rem;
   height: 1.7rem;
   background-color: var(--background);
   border-radius: 50%;
   margin-right: 1.8rem;
   z-index: 1;
}

#tracking-container .status-container {
   width: 15rem;
   display: flex;
   flex-direction: column;
   margin-right: 5rem;
}

#tracking-container .status-container .status {
   font-family: 'Montserrat', sans-serif;
   font-size: 1.4rem;
   font-weight: 600;
   line-height: 2.1rem;
}

#tracking-container .status-container .status-explanation {
   font-family: 'Montserrat', sans-serif;
   font-size: 1.2rem;
   font-weight: 400;
   line-height: 1.8rem;
}

#tracking-container .date {
   font-family: 'Montserrat', sans-serif;
   font-size: 1.4rem;
   font-weight: 600;
   line-height: 2.1rem;
   color: var(--label);
}
