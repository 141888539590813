#modal-upload {
   display: flex;
   justify-content: center;
   align-items: center;
}

#modal-upload .modal-content {
   width: 94rem;
   border-radius: 2rem;
}

#modal-upload .modal-content .modal-body {
   position: relative;
   width: 94rem;
   min-height: 56rem;
   background: rgba(255, 255, 255, 0.9);
   box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
   backdrop-filter: blur(24px);
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: space-evenly;
   border-radius: 2rem;
   padding: 5.4rem 4rem;
}

#modal-upload .modal-content .modal-body .button-x {
   position: absolute;
   right: 3rem;
   top: 3rem;
   border: 0;
   background: transparent;
}

#modal-upload .modal-content .modal-body .header {
   width: 100%;
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
}

#modal-upload .modal-content .modal-body .header h1 {
   font-family: 'Montserrat', sans-serif;
   font-size: 2.4rem;
   font-weight: 600;
   line-height: 2.4rem;
   color: var(--black);
}

#modal-upload .modal-content .modal-body .vessel-input-container {
   margin-top: 2.4rem;
}

#modal-upload .modal-content .modal-body .vessel-input-container .input-block {
   margin: 0;
}

#modal-upload .modal-content .modal-body .vessel-input-container .input-block:focus-within::after {
   width: calc(24.3rem - 3.2rem);
   height: 1px;
   content: '';
   background: var(--blue-color);
   position: absolute;
   left: 1.6rem;
   right: 1.6rem;
   bottom: 0.2rem;
}

#modal-upload .modal-content .modal-body .vessel-input-container .input-block label {
   color: #32264C;
}

#modal-upload .modal-content .modal-body .input-block .react-select-container {
   width: 24.3rem;
   height: 3.2rem;
   margin-top: 0.5rem;
   border-radius: 0.8rem;
   background: var(--white);
   border: 1px solid var(--border-line);
   outline: none;
   padding: 0 1rem;
   font-family: 'Montserrat', sans-serif;
   font-size: 1.2rem;
   font-weight: 400;
   color: var(--black-grey);
   cursor: pointer;
}

#modal-upload .modal-content .modal-body .input-block .react-select__control {
   width: 24.3rem;
   height: 3.2rem;
   border: 0;
   background-color: transparent;
   display: flex;
   align-items: center;
}

#modal-upload .modal-content .modal-body .input-block .react-select__value-container {
   width: 24.3rem;
   height: 3.2rem;
   border: 0;
   background-color: transparent;
   display: flex;
   align-items: center;
   position: static;
}

#modal-upload .modal-content .modal-body .input-block .react-select__input {
   height: 0;
   border: 0;
   background-color: transparent;
}

#modal-upload .modal-content .modal-body .input-block .react-select__menu {
   width: 93%;
}

#modal-upload .modal-content .modal-body .input-block .react-select__option:active {
   background-color: #4B81E8;
   color: #FFF;
}

#modal-upload .modal-content .modal-body .input-block .react-select__indicators {
   display: none;
}

#modal-upload .modal-content .modal-body .input-block .react-select__menu-list::-webkit-scrollbar {
   width: 0.7rem;
}

#modal-upload .modal-content .modal-body .input-block .react-select__menu-list::-webkit-scrollbar-thumb {
   background: #AAA;
   border-radius: 1rem;
}

#modal-upload .modal-content .modal-body .input-block .react-select__menu-list::-webkit-scrollbar-thumb:hover {
   background: #888;
}

#modal-upload .modal-content .modal-body .drag-drop-container {
   width: 86rem;
   height: 27rem;
   display: flex;
   justify-content: center;
   align-items: center;
   border: 2px dashed rgba(50, 38, 76, 0.5);
   border-radius: 2rem;
   margin: 3.2rem auto;
   outline: none;
   cursor: pointer;
   overflow: hidden;
   flex-wrap: wrap;
}

#modal-upload .modal-content .modal-body .button-container {
   width: 32rem;
   display: flex;
   flex-direction: row;
   justify-content: space-evenly;
   align-items: center;
}

#modal-upload .modal-content .modal-body .button-container .button-submit {
   width: 12rem;
   height: 4.5rem;
   background-color: var(--blue-color);
   border: 0;
   border-radius: 0.4rem;
   display: flex;
   align-items: center;
   justify-content: center;
   font-family: 'Montserrat', sans-serif;
   font-size: 1.44rem;
   font-weight: 700;
   line-height: 1.8rem;
   color: var(--white);
   transition: background-color 0.2s;
}

#modal-upload .modal-content .modal-body .button-container .button-submit:hover {
   background-color: var(--blue-color-dark);
}

#modal-upload .modal-content .modal-body .button-container .button-cancel {
   width: 12rem;
   height: 4.5rem;
   background-color: transparent;
   border: 1px solid var(--black);
   border-radius: 0.4rem;
   display: flex;
   align-items: center;
   justify-content: center;
   font-family: 'Montserrat', sans-serif;
   font-size: 1.44rem;
   font-weight: 600;
   line-height: 1.8rem;
   color: var(--black);
   transition: opacity 0.2s;
}

#modal-upload .modal-content .modal-body .button-container .button-cancel:hover {
   opacity: 0.5;
}

#modal-upload .modal-content .modal-body .drag-drop-container .no-content {
   width: 27rem;
   display: flex;
   flex-direction: column;
   align-items: center;
}

#modal-upload .modal-content .modal-body .drag-drop-container .drop-here {
   font-family: 'Montserrat', sans-serif;
   font-size: 1.4rem;
   font-weight: 400;
   line-height: 2rem;
   color: var(--black);
   text-align: center;
}

#modal-upload .modal-content .modal-body .drag-drop-container .file {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   margin-right: 2rem;
}

#modal-upload .modal-content .modal-body .drag-drop-container .file span {
   font-family: 'Montserrat', sans-serif;
   font-size: 1.4rem;
   font-weight: 400;
   line-height: 2rem;
   color: var(--black);
   text-align: center;
   margin-top: 1rem;
}

#modal-upload .modal-content .modal-body .drag-drop-container .no-content .cloud {
   width: 5.6rem;
   height: 5.6rem;
   background-color: var(--blue-color);
   border-radius: 1rem;
   backdrop-filter: blur(24px);
   display: flex;
   align-items: center;
   justify-content: center;
   margin-bottom: 2rem;
}

#modal-upload .modal-content .modal-body .drag-drop-container .no-content span {
   font-family: 'Montserrat', sans-serif;
   font-size: 1.4rem;
   font-weight: 300;
   line-height: 2.4rem;
   color: #32264C;
   text-align: center;
}

#modal-upload .modal-content .modal-body .info-success {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
}

#modal-upload .modal-content .modal-body .info-success .success-message {
   font-family: 'Montserrat', sans-serif;
   font-size: 2.4rem;
   font-weight: 600;
   line-height: 2.4rem;
   color: #404040;
   text-align: center;
   margin-top: 2.5rem;
}

#modal-upload .modal-content .modal-body .info-success .success-but-error {
   width: 72rem;
   height: 18rem;
   background: rgba(225, 220, 110, 0.1);
   border-radius: 2rem;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: space-between;
   padding: 2rem 0;
   margin-top: 3.2rem;
   overflow: hidden;
}

#modal-upload .modal-content .modal-body .info-success .success-but-error .info {
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: center;
}

#modal-upload .modal-content .modal-body .info-success .success-but-error .info .success-but-error-message {
   font-family: 'Montserrat', sans-serif;
   font-size: 1.5rem;
   font-weight: 600;
   line-height: 2.4rem;
   color: #404040;
   text-align: center;
   margin-left: 1rem;
}

#modal-upload .modal-content .modal-body .info-success .success-but-error .csv-button {
   width: 12rem;
   height: 4.5rem;
   border: 1px solid var(--black);
   border-radius: 0.4rem;
   background-color: transparent;
   display: flex;
   justify-content: center;
   align-items: center;
   text-decoration: none;
   font-family: 'Montserrat', sans-serif;
   font-size: 1.44rem;
   font-weight: 400;
   line-height: 1.8rem;
   color: var(--black);
   transition: opacity 0.2s;
}

#modal-upload .modal-content .modal-body .info-success .success-but-error .csv-button:hover {
   opacity: 0.5;
}

#modal-upload .modal-content .modal-body .info-success .success-but-error .footer-message {
   font-family: 'Montserrat', sans-serif;
   font-size: 1.3rem;
   font-weight: 400;
   line-height: 2.4rem;
   color: #404040;
   text-align: center;
}

#modal-upload .modal-content .modal-body .info-success .success-but-error .footer-message a {
   font-family: 'Montserrat', sans-serif;
   font-size: 1.3rem;
   font-weight: 400;
   line-height: 2.4rem;
   color: #404040;
   text-align: center;
   text-decoration-line: underline;
}

#modal-upload .modal-content .modal-body .info-failed {
   width: 72rem;
   height: 24rem;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   background: rgba(255, 80, 80, 0.1);
   border-radius: 20px;
}

#modal-upload .modal-content .modal-body .info-failed .failed-svg-container {
   width: 5.8rem;
   height: 5.8rem;
   display: flex;
   justify-content: center;
   align-items: center;
   background: #FF5050;
   border-radius: 1rem;
}

#modal-upload .modal-content .modal-body .info-failed h3 {
   font-family: 'Montserrat', sans-serif;
   font-size: 2.4rem;
   font-weight: 600;
   line-height: 2.4rem;
   color: #404040;
   text-align: center;
   margin-top: 2.5rem;
}

#modal-upload .modal-content .modal-body .info-failed span {
   font-family: 'Montserrat', sans-serif;
   font-size: 1.4rem;
   font-weight: 400;
   line-height: 2.4rem;
   color: #404040;
   text-align: center;
   margin-top: 1rem;
}
