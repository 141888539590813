#page-details-seaTransport {
   background: var(--background);
   overflow: hidden;
}

#page-details-seaTransport .container {
   width: 100%;
   height: calc(100vh - 18rem);
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
   margin-top: 2rem;
}

#page-details-seaTransport .container .container-left {
   width: 100%;
   height: 100%;
   display: flex;
   flex: 1;
   margin-right: 0.5rem;
}

#page-details-seaTransport .container .container-right {
   width: 100%;
   height: 100%;
   display: flex;
   flex: 1;
   flex-direction: column;
   margin-left: 0.5rem;
}

#page-details-seaTransport .container .container-right h2 {
   font-family: 'Montserrat', sans-serif;
   font-size: 1.4rem;
   font-weight: 600;
   line-height: 2.1rem;
   color: var(--label);
   margin-bottom: 1.5rem;
}

#page-details-seaTransport .container .container-right .form {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
}

#page-details-seaTransport .container .container-right .form .button-date-picker {
   width: 23.5rem;
   height: 3.2rem;
   border-radius: 0.8rem;
   background: var(--white);
   border: 1px solid var(--border-line);
   outline: none;
   margin-top: 0.2rem;
   padding: 0 1.6rem;
   font-family: 'Montserrat', sans-serif;
   font-size: 1.2rem;
   font-weight: 400;
   line-height: 2.4rem;
   color: var(--black-grey);
   display: flex;
   justify-content: flex-start;
   align-items: center;
}

#page-details-seaTransport .container .container-right .form .button-date-picker:disabled {
   background: var(--gray-color);
}

#page-details-seaTransport .container-right .form .label-container {
   width: 23.5rem;
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: space-between;
}

#page-details-seaTransport .container-right .form label {
   font-family: 'Poppins', sans-serif;
   font-size: 1.2rem;
   font-weight: 400;
   line-height: 2.2rem;
   color: var(--label);
}

#page-details-seaTransport .container-right .form .button-edit {
   width: 2rem;
   height: 2rem;
   border: 0;
   background: transparent;
   display: none;
}

#page-details-seaTransport .container-right .form #input-block:hover .button-edit {
   display: block;
}

#page-details-seaTransport .container-right .form .react-select-container {
   width: 23.5rem;
   height: 3.2rem;
   border-radius: 0.8rem;
   border: 1px solid var(--border-line);
   outline: none;
   margin-top: 0.2rem;
   padding: 0 1rem;
   font-family: 'Montserrat', sans-serif;
   font-size: 1.2rem;
   font-weight: 400;
   color: var(--black-grey);
   text-transform: capitalize;
   cursor: pointer;
}

#page-details-seaTransport .container-right .form .react-select__placeholder {
   color: var(--black-grey);
}

#page-details-seaTransport .container-right .form .react-select__control {
   width: 23.5rem;
   height: 3.2rem;
   border: 0;
   background-color: transparent;
   display: flex;
   align-items: center;
}

#page-details-seaTransport .container-right .form .react-select__value-container {
   width: 23.5rem;
   height: 3.2rem;
   border: 0;
   background-color: transparent;
   display: flex;
   align-items: center;
}

#page-details-seaTransport .container-right .form .react-select__input {
   height: 0;
   border: 0;
   background-color: transparent;
}

#page-details-seaTransport .container-right .form .react-select__menu {
   width: 93%;
}

#page-details-seaTransport .container-right .form .react-select__option:active {
   background-color: #4B81E8;
   color: #FFF;
}

#page-details-seaTransport .container-right .form .react-select__indicators {
   display: none;
}

#page-details-seaTransport .container-right .form .react-select__menu-list::-webkit-scrollbar {
   width: 0.7rem;
}

#page-details-seaTransport .container-right .form .react-select__menu-list::-webkit-scrollbar-thumb {
   background: #AAA;
   border-radius: 1rem;
}

#page-details-seaTransport .container-right .form .react-select__menu-list::-webkit-scrollbar-thumb:hover {
   background: #888;
}
