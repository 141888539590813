#page-to-be-received {
   flex: 1;
   width: 100vw;
   background: var(--background);
   overflow-x: hidden;
}

#page-to-be-received .height {
   height: calc(100vh - 25rem);
}

#page-to-be-received h2 {
   font-family: 'Montserrat', sans-serif;
   font-size: 1.4rem;
   font-weight: 600;
   line-height: 2.1rem;
   color: var(--label);
   margin: 3.2rem 0 2.1rem;
}

#page-to-be-received h3 {
   font-family: 'Montserrat', sans-serif;
   font-size: 1.4rem;
   font-weight: 600;
   line-height: 2.1rem;
   color: var(--label);
   margin: 0;
}

#page-to-be-received .chart-container-line {
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: space-between;
}

#page-to-be-received .chart-container-small {
   width: 40rem;
   height: 25.2rem;
   background: var(--white);
   border: 1px solid var(--border-line);
   display: flex;
   flex-direction: column;
   padding: 1.4rem 2.7rem;
   overflow: hidden;
}

#page-to-be-received .in-block {
   height: 25.2rem;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: space-between;
}

#page-to-be-received .info-container {
   width: 26rem;
   height: 11.6rem;
   background: var(--white);
   border: 1px solid var(--border-line);
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
}

#page-to-be-received .info-container span {
   font-family: 'Montserrat', sans-serif;
   font-size: 5.4rem;
   font-weight: 600;
   color: var(--black);
}
