#chassi-highlights h2 {
   font-family: 'Montserrat', sans-serif;
   font-size: 1.4rem;
   font-weight: 600;
   line-height: 2.1rem;
   color: var(--label);
   margin-bottom: 1rem;
}

#chassi-highlights .form {
   width: 33rem;
   flex: 1;
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   flex-wrap: wrap;
}

#chassi-highlights .form .input-block {
   display: flex;
   flex-direction: column;
   margin: 1rem auto;
   flex: 1;
}

#chassi-highlights .form .input-block .label-container-model-code {
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: space-between;
   width: 10rem;
}

#chassi-highlights .form .input-block .label-container-status {
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: space-between;
   width: 16rem;
}

#chassi-highlights .form .input-block label {
   font-family: 'Poppins', sans-serif;
   font-size: 1.2rem;
   font-weight: 400;
   line-height: 2.2rem;
   color: var(--label);
}

#chassi-highlights .form .input-block .button-edit {
   width: 2rem;
   height: 2rem;
   border: 0;
   background: transparent;
   display: none;
}

#chassi-highlights .form .input-block:hover .button-edit {
   display: block;
}

#chassi-highlights .form .input-block input {
   height: 3.2rem;
   border-radius: 0.8rem;
   background: var(--white);
   border: 1px solid var(--border-line);
   outline: none;
   margin-top: 0.2rem;
   padding: 0 1.6rem;
   font-family: 'Montserrat', sans-serif;
   font-size: 1.2rem;
   font-weight: 400;
   line-height: 2.4rem;
   color: var(--black-grey);
}

#chassi-highlights .form .input-block .model-code {
   width: 10rem;
   text-transform: uppercase;
}

#chassi-highlights .form .input-block .model-maker {
   width: 20rem;
   text-transform: uppercase;
}

#chassi-highlights .form .input-block .assembly-plant {
   width: 15rem;
   text-transform: uppercase;
}

#chassi-highlights .form .input-block .current-status {
   width: 16rem;
   text-transform: capitalize;
}

#chassi-highlights .form .input-block .aging {
   width: 15rem;
}

#chassi-highlights .form .input-block input:disabled {
   background: var(--gray-color);
}

#chassi-highlights .form .input-block input:-webkit-autofill {
   -webkit-box-shadow: 0 0 0px 1000px var(--white) inset !important;
   box-shadow: 0 0 0px 1000px var(--white) inset !important;
}

#chassi-highlights .form .input-block input:focus {
   border: 2px solid var(--blue-color);
}

#chassi-highlights .form .input-block:focus-within::after {
   width: 0;
   height: 0;
   content: '';
   background: var(--blue-color);
   position: absolute;
   left: 1.6rem;
   right: 1.6rem;
   bottom: 0.7rem;
}

#chassi-highlights .form .input-block .react-select-container {
   width: 16rem;
   height: 3.2rem;
   border-radius: 0.8rem;
   border: 1px solid var(--border-line);
   outline: none;
   margin-top: 0.2rem;
   padding: 0 1rem;
   font-family: 'Montserrat', sans-serif;
   font-size: 1.2rem;
   font-weight: 400;
   color: var(--black-grey);
   text-transform: capitalize;
   cursor: pointer;
}

#chassi-highlights .form .input-block .react-select__placeholder {
   color: var(--black-grey);
}

#chassi-highlights .form .input-block .react-select__control {
   width: 16rem;
   height: 3.2rem;
   border: 0;
   background-color: transparent;
   display: flex;
   align-items: center;
}

#chassi-highlights .form .input-block .react-select__value-container {
   width: 16rem;
   height: 3.2rem;
   border: 0;
   background-color: transparent;
   display: flex;
   align-items: center;
}

#chassi-highlights .form .input-block .react-select__input {
   height: 0;
   border: 0;
   background-color: transparent;
}

#chassi-highlights .form .input-block .react-select__menu {
   width: 93%;
}

#chassi-highlights .form .input-block .react-select__option:active {
   background-color: #4B81E8;
   color: #FFF;
}

#chassi-highlights .form .input-block .react-select__indicators {
   display: none;
}
