#page-error {
   width: 100vw;
   height: 100vh;
   background: var(--background);
   overflow: hidden;
   display: flex;
   justify-content: space-evenly;
   align-items: center;
}

#page-error .left {
   height: 40rem;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: flex-start;
}

#page-error .left img {
   width: 27.6rem;
   height: 6.4rem;
}

#page-error .left h1 {
   font-family: 'Montserrat', sans-serif;
   font-size: 2.2rem;
   font-weight: 700;
   line-height: 2.7rem;
   color: var(--black);
}

#page-error .left span {
   font-family: 'Montserrat', sans-serif;
   font-size: 2.2rem;
   font-weight: 600;
   line-height: 4rem;
   color: var(--black);
}

#page-error .left button {
   width: 12rem;
   height: 4.5rem;
   border: 1px solid var(--black);
   border-radius: 0.4rem;
   background-color: transparent;
   font-family: 'Montserrat', sans-serif;
   font-size: 1.44rem;
   font-weight: 400;
   line-height: 1.8rem;
   color: var(--black);
   transition: opacity 0.2s;
}

#page-error .left button:hover {
   opacity: 0.5;
}

#page-error img {
   width: 38.5rem;
   height: 38.5rem;
}
