#page-login {
   width: 100vw;
   height: 100vh;
   overflow: auto;
   overflow-x: hidden;
   background: url('../../../assets/bkg_porto.png') no-repeat center;
   -webkit-background-size: cover;
   -moz-background-size: cover;
   -o-background-size: cover;
   background-size: cover;
}

#page-login .red-line {
   width: 100vw;
   height: 2rem;
   background-color: var(--red-color);
}

#page-login .page-login-container {
   height: calc(100% - 10rem);
   flex: 1;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: flex-end;
}

#page-login .content-container {
   width: 39.9rem;
   height: 55.6rem;
   background: rgba(255, 255, 255, 0.3);
   backdrop-filter: blur(24px);
   border-radius: 1rem;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   margin-right: 24.5rem;
}

#page-login .content-container main {
   width: 27rem;
   display: flex;
   flex-direction: column;
   align-items: flex-start;
}

#page-login .content-container main h1 {
   font-family: 'Fjalla One', sans-serif;
   font-size: 2.6rem;
   font-weight: 400;
   line-height: 3.3rem;
   color: var(--white);
}

#page-login .content-container main h2 {
   font-family: 'Fjalla One', sans-serif;
   font-size: 2.6rem;
   font-weight: 400;
   line-height: 3.3rem;
   color: var(--white);
}

#page-login .content-container main span {
   font-family: 'Montserrat', sans-serif;
   font-size: 1.44rem;
   font-weight: 400;
   line-height: 1.9rem;
   color: var(--white);
   margin: 1rem 0 1.2rem;
}

#page-login .content-container main .line {
   width: 100%;
   border-bottom: 1px solid #F2F2F2;
}

#page-login .content-container main form {
   width: 100%;
   display: flex;
   flex-direction: column;
}

#page-login .content-container main .buttons-container {
   width: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   margin-top: 3rem
}

#page-login .content-container main .enter-button {
   width: 20.1rem;
   height: 4.8rem;
   background: var(--blue-color);
   border-radius: 0.8rem;
   font-family: 'Montserrat', sans-serif;
   font-size: 1.44rem;
   font-weight: 600;
   line-height: 2.6rem;
   color: var(--white);
   display: flex;
   align-items: center;
   justify-content: center;
   margin-bottom: 1.4rem;
   border: 0;
   transition: background-color 0.2s;
}

#page-login .content-container main .enter-button:hover {
   background: var(--blue-color-dark);
}

#page-login .content-container main .forgot-password-button {
   width: 20.1rem;
   height: 4.8rem;
   background: transparent;
   border-radius: 0.8rem;
   border: 1px solid var(--white);
   font-family: 'Montserrat', sans-serif;
   font-size: 1.2rem;
   font-weight: 400;
   line-height: 2.6rem;
   color: var(--white);
   display: flex;
   align-items: center;
   justify-content: center;
   transition: opacity 0.2s;
   text-decoration: none;
}

#page-login .content-container main .forgot-password-button:hover {
   opacity: 0.5;
}

#page-login .content-container .input-container {
   position: relative;
}

#page-login .content-container .input-container svg {
   position: absolute;
   right: 1.3rem;
   top: 4.5rem;
   cursor: pointer;
}
